body {
  .daterangepicker {
    font-family: "Poppins", sans-serif;
    box-shadow: 0 0 50px rgba(82, 63, 105, 0.35);

    .month,
    th {
      font-weight: normal;
    }

    .prev,
    .next {
      span {
        border-width: 0 1px 1px 0 !important;
        border-color: $gray-900;
      }
    }

    .active {
      background-color: $primary !important;
    }

    .drp-calendar {
      margin-bottom: 3rem;
      &.left {
        border-left: 0 !important;
      }
    }

    &.show-ranges {
      .ranges {
        padding-bottom: 4rem;
        border-right: 1px solid #ddd;
      }
    }

    td.in-range {
      background-color: #f3f6f9;
    }

    .calendar-time {
      select {
        background-color: transparent;
        border-radius: $border-radius;
      }
    }
  }
}

$size: 1.5;
$border-width: 2px;
$translate: calc($size/3);

ol.progress {
  list-style: none;
  padding: 0;
  overflow: hidden;
  min-width: max-content;
  border: $border-width solid $blue-50;
  height: #{$size}rem;
  display: flex;

  li {
    background-color: $blue-50;
    position: relative;
    height: #{$size}rem;
    background-color: $blue-50;
    max-width: 100%;
    flex: 1;
  }

  li.blank+li.full::after {
    background-color: $white;
    left: 0;
  }

  li.blank {
    background-color: $white;
    transform: scaleX(1.1);
  }

  li::after {
    transform: rotate(45deg) translate(-0.5rem, calc(#{$translate}rem - #{$border-width}));
    right: 100%;
  }

  li.full::after {
    content: "";
    position: absolute;
    margin-left: -0.5rem;
    width: #{$size}rem;
    padding-top: #{$size}rem;
    border: solid white;
    z-index: 1;
    border-bottom: 0;
    border-left: 0;
  }

  li.full+li.blank::after {
    right: calc(100% - #{$size}rem);
  }

  li.blank+li.blank::after {
    background-color: $white;
    border: $border-width solid $blue-50;
    border-bottom-width: 0;
    border-left-width: 0;
  }

  li.blank::after {
    content: "";
    position: absolute;
    height: #{$size}rem;
    width: #{$size}rem;
    border: 0;
    background-color: $blue-50;
    padding-top: 0;
    top: unset;
    right: 75%;
  }

  li.first::after {
    content: none;
  }
}
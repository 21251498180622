#jt-app-card {
  box-shadow: 0 0 20px $gray-400;
  @include media-breakpoint-up(lg) {
    min-height: 700px;
  }
}

#jt-app {
  padding-top: 20px;
  padding-bottom: 20px;
}

#jt-app-header {
}

#jt-app-body {
}

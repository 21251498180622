.dataTables {
  &_info {
    padding-top: 0 !important;
    margin-left: 1rem;
  }
  &_wrapper {
    tfoot {
      visibility: hidden;
    }
  }

  &_length {
    option {
      &:focus,
      &:hover {
        background-color: $gray-400;
      }
    }
    label {
      width: 100%;
    }
  }

  &_wrapper {
    .form-control.dropdown-toggle {
      .filter-option {
        display: flex;
        align-items: center;
      }
    }
  }
}

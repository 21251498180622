.typeahead__container {
  font-size: $font-size-base !important;
  input.form-control {
    border-radius: $border-radius;
  }

  .typeahead__list {
    border: 0;
    box-shadow: 0 0 20px lightgrey;

    li {
      border-top: 0;
      strong {
        font-weight: 600;
      }
    }
  }
}

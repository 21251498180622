$count: 10;

@for $i from 0 through $count {
  .border-#{$i} {
    border-width: #{$i}px;
  }

  .border-t-#{$i} {
    border-top-width: #{$i}px;
  }

  .border-r-#{$i} {
    border-right-width: #{$i}px;
  }

  .border-b-#{$i} {
    border-bottom-width: #{$i}px;
  }

  .border-l-#{$i} {
    border-left-width: #{$i}px;
  }
}


// variables must come first
@import "_custom-maps.scss";
@import "_variable-overrides.scss";

@import "~bootstrap/scss/bootstrap";

// Custom Utilities
@import "_custom-mixins.scss";
@import "_custom-utilities/all.scss";

@import "./bs-component-overrides/all";
@import "./plugin-overrides/all";
@import "./components/all.scss";
@import "./fontawesome-pro-5.15.4-web/css/all.css";

// DataGrail update
.dg-main{
    max-height: 50vh;
}
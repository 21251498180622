$percents: (
  0: 0%,
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  33: 33.3%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  66: 66.6%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%,
  95: 95%,
  100: 100%,
);

$vws: (
  0: 0vw,
  5: 5vw,
  10: 10vw,
  15: 15vw,
  20: 20vw,
  25: 25vw,
  30: 30vw,
  33: 33.3vw,
  35: 35vw,
  40: 40vw,
  45: 45vw,
  50: 50vw,
  55: 55vw,
  60: 60vw,
  65: 65vw,
  66: 66.6vw,
  70: 70vw,
  75: 75vw,
  80: 80vw,
  85: 85vw,
  90: 90vw,
  95: 95vw,
  100: 100vw,
);

$vhs: (
  0: 0vh,
  25: 25vh,
  50: 50vh,
  75: 75vh,
  100: 100vh,
);

$rems: (
  0: 0rem,
  25: 0.25rem,
  5: 0.5rem,
  75: 0.75rem,
  100: 1rem,
  125: 1.25rem,
  150: 1.5rem,
  175: 1.75rem,
  200: 2rem,
  225: 2.25rem,
  250: 2.5rem,
  275: 2.75rem,
  300: 3rem,
  325: 3.25rem,
  350: 3.5rem,
  375: 3.75rem,
  400: 4rem,
  425: 4.25rem,
  450: 4.5rem,
  475: 4.75rem,
  500: 5rem,
  525: 5.25rem,
  550: 5.5rem,
  575: 5.75rem,
  600: 6rem,
);

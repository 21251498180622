.was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
  border-color: $success !important;
}

.was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
  border-color: $danger !important;
}

.bootstrap-select {
  li.selected:not(:disabled) {
    background-color: $dropdown-link-active-bg;
    .check-mark {
      top: unset !important;
    }
  }
}

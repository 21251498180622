$count: 6;

@mixin position-top($size) {
  top: #{$size}rem;
}
@mixin position-right($size) {
  right: #{$size}rem;
}
@mixin position-bottom($size) {
  bottom: #{$size}rem;
}
@mixin position-left($size) {
  left: #{$size}rem;
}

@for $i from 0 through $count {
  .t-#{$i} {
    @include position-top($i);
  }
  .r-#{$i} {
    @include position-right($i);
  }
  .b-#{$i} {
    @include position-bottom($i);
  }
  .l-#{$i} {
    @include position-left($i);
  }
}

.r-unset {
  right: unset !important;
}
.l-unset {
  left: unset !important;
}
.t-unset {
  top: unset !important;
}
.b-unset {
  bottom: unset !important;
}

$count: 10;

@for $i from 0 through $count {
  .h-#{$i} {
    height: #{$i}rem;
  }
  .w-#{$i} {
    width: #{$i}rem;
  }
}

.tp {
  @include util-by-breakpoint($percents, top);
}

.rp {
  @include util-by-breakpoint($percents, right);
}

.bp {
  @include util-by-breakpoint($percents, bottom);
}

.lp {
  @include util-by-breakpoint($percents, left);
}

// custom variables
$dark: rgba(24, 28, 50, 1);
//$jt-accent: #f2d34b;// deprecated
//$jt-red: #cc0000; //deprecated
//$jt-green: #3f9c35; //deprecated
$dark-75: rgba(24, 28, 50, 0.75);
$dark-50: rgba(24, 28, 50, 0.5);
$dark-25: rgba(24, 28, 50, 0.25);
$light-primary: #e1f0ff;
$light-success: #c9f7f5;
$light-danger: #ffe2e5;
$light-warning: #fff4de;
$light-info: #eee5ff;
$dark-fill: #d1d3e0;
$light-fill: #f3f6f9;
$dark-warning: #855700;
$dark-primary: #184673;
$dark-danger: #802932;
$dark-success: #0e6662;
$dark-info: #5c3d9d;
$subtitle: #526069;
$blue-90: #4392e0;
$blue-80: #599ee4;
$blue-70: #6daae7;
$blue-60: #8cb6eb;
$blue-50: #97c2ee;
$blue-40: #accff1;
$blue-30: #c0daf5;
$blue-20: #d5e7f8;
$blue-10: #eaf3fc;
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// base styles
$enable-rounded: true;
$border-radius: 0.313rem;
$font-family-sans-serif: "Poppins", sans-serif;

//colors
$body-bg: #f2f3f7;
$body-color: #3f4254;
$text-muted: #b5b5c3;
$border-color: #e4e6ef;
$headings-color: $dark;

$blue: #2f86dd;
$success: #1bc5bd;
$info: #8950fc;
$warning: #ffa800;
$danger: #f64e60;

$theme-colors: (
  //"jt-accent": #f2d34b,
  //"jt-red": #cc0000,
  //deprecated
  //"jt-green": #3f9c35,
  //deprecated
  "dark": $dark,
  "dark-75": $dark-75,
  "dark-50": $dark-50,
  "dark-25": $dark-25,
  "light-primary": $light-primary,
  "light-success": $light-success,
  "light-danger": $light-danger,
  "light-warning": $light-warning,
  "light-info": $light-info,
  "dark-success": $dark-success,
  "dark-warning": $dark-warning,
  "dark-primary": $dark-primary,
  "dark-danger": $dark-danger,
  "dark-info": $dark-info,
  "dark-fill": $dark-fill,
  "light-fill": $light-fill,
  "subtitle": $subtitle,
  "blue-90": $blue-90,
  "blue-80": $blue-80,
  "blue-70": $blue-70,
  "blue-60": $blue-60,
  "blue-50": $blue-50,
  "blue-40": $blue-40,
  "blue-30": $blue-30,
  "blue-20": $blue-20,
  "blue-10": $blue-10,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900
);

// dropdowns
$dropdown-border-radius: 0 0 0.42rem 0.42rem;
$dropdown-border-width: 0;
$dropdown-box-shadow: 0 0 50px rgba(82, 63, 105, 0.15);

$dropdown-item-padding-y: 0.75rem !default;
$dropdown-item-padding-x: 1.25rem !default;

$dropdown-link-hover-color: $body-color;
$dropdown-link-active-color: $body-color;
$dropdown-link-hover-bg: #f3f6f9;
$dropdown-link-active-bg: #f3f6f9;

//fonts
$font-size-base: 0.875rem;
$font-size-base-sm: 0.75rem;

$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

//grid system
$container-max-widths: (
  xl: 1440px,
);

//inputs
$input-focus-border-color: $blue;
$input-focus-box-shadow: 0;
$input-btn-font-size: 0.813rem;
$input-btn-padding-y: 0.51rem;
$input-border-color: #e4e6ef;
$input-height: 2.375rem;
$input-height-sm: 2.063rem;
$input-height-lg: 2.813rem;
//modals
$modal-backdrop-opacity: 0.2;
$modal-content-border-width: 0;
$modal-header-border-width: 1px;
$modal-footer-border-width: 1px;
$modal-dialog-margin-y-sm-up: 13vh;

//navs
$nav-link-padding-x: 1.15rem;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-color: $blue;
$nav-tabs-border-color: transparent;

//cards
$card-border-color: #ebedf3;

//pagination
$pagination-bg: $light-fill;
$pagination-border-width: 0;
$pagination-color: $subtitle;
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-padding-y-sm: 0;
$pagination-padding-y-lg: 0;
$pagination-padding-x-lg: 0;

//popovers
$popover-font-size: $font-size-base;
$popover-border-color: $blue;
$popover-border-width: 2px;
$popover-body-color: $blue;
$popover-arrow-outer-color: $blue;

//tables
$table-cell-padding: 1rem;

$_sm-times: $font-size-base * 0.75;
$_lg-times: $font-size-base * 1.25;
$_xl-times: $font-size-base * 1.66;

.text {
  &-base {
    font-size: $font-size-base;
  }

  &-sm {
    font-size: $_sm-times;
  }

  &-lg {
    font-size: $_lg-times;
  }

  &-xl {
    font-size: $_xl-times;
  }
}

$count: 6;

@for $i from 0 through $count {
  .text-#{$i} {
    font-size: #{$i}rem;
  }
}

@for $i from 0 through $count {
  .text-#{$i}5 {
    font-size: #{$i + 0.5}rem;
  }
}

.text {
  @include util-by-breakpoint($rems, font-size);
}

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold;
}

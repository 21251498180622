.pagination {
  margin-bottom: 0 !important;
  font-size: $font-size-base-sm;
  .page-item {
    margin: 0 0.25rem;
    border-radius: $border-radius !important;

    &.disabled {
      .page-link {
        background-color: $light-fill;
        opacity: 0.8;
      }
    }
  }

  &.pagination-sm {
    font-size: $font-size-base-sm * 0.75;
    .page-link {
      height: 1.5rem;
      min-width: 1.5rem;
      font-size: inherit;
    }
  }

  .page-link {
    border-radius: $border-radius !important;
    height: 1.75rem;
    min-width: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-lg {
    font-size: $font-size-base-sm;
    .page-link {
      height: 2rem;
      width: 2rem;
    }
  }
}

.JTOffcanvas {
    // Panel
    &__panel {
        position: fixed;
        background-color: $white;
        box-shadow: 2px 2px 10px $gray-500;
        z-index: $zindex-modal;
        transition: transform .3s;


        &.left {
            top: 0;
            left: 0;
            transform: translateX(-100%);
        }

        &.right {
            top: 0;
            right: 0;
            transform: translateX(100%);
        }

        &.top {
            left: 0;
            top: 0;
            transform: translateY(-100%);
        }

        &.bottom {
            left: 0;
            bottom: 0;
            transform: translateY(100%);
        }

        &.show {
            &.left {
                top: 0;
                left: 0;
                transform: translateX(0);
            }

            &.right {
                right: 0;
                top: 0;
                transform: translateX(0);
            }

            &.top {
                left: 0;
                top: 0;
                transform: translateY(0);
            }

            &.bottom {
                left: 0;
                bottom: 0;
                transform: translateY(0);
            }
        }

        &--scrollable {
            overflow-y: auto;
        }
    }

    // Backdrop
    &__backdrop {
      background-color: $gray-800;
      opacity: .5;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: $zindex-modal-backdrop;
      display: none;

        &.show{
            display: block;
        }
    }
}

svg text {
  font-family: $font-family-sans-serif;
}

.highcharts {
  &-container {
    overflow: visible !important;
  }
  &-contextmenu {
    border-radius: $dropdown-border-radius;
    border-width: 0 !important;
    padding-bottom: 0 !important;
  }

  &-menu {
    border-radius: $dropdown-border-radius;
    border-width: 0 !important;
    box-shadow: $dropdown-box-shadow !important;
  }

  &-menu-item {
    &:hover,
    &:focus,
    &:active {
      color: $body-color !important;
      background: #f3f6f9 !important;
    }
  }
}

.collapse-highlight {
  border-bottom: 3px solid transparent;
  &[aria-expanded="true"] {
    border-bottom-color: $blue;
  }
}

[data-toggle="collapse"][aria-expanded="true"] {
  .fa-plus-circle {
    display: none;
  }
  .fa-minus-circle {
    display: inline-block;
  }
}

[data-toggle="collapse"][aria-expanded="false"] {
  .fa-plus-circle {
    display: inline-block;
  }
  .fa-minus-circle {
    display: none;
  }
}

$count: 10;

@for $i from 0 through $count {
  .h-#{$i} {
    height: #{$i}rem;
  }
  .w-#{$i} {
    width: #{$i}rem;
  }
}

.wp {
  @include util-by-breakpoint($percents, width);
}

.vw {
  @include util-by-breakpoint($vws, width);
}

.vh {
  @include util-by-breakpoint($vhs, height);
}

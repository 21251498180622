label {
  color: $dark;
}

.bootstrap-select {
  .form-control.dropdown-toggle:focus {
    outline: 0 !important;
  }
}

.was-validated {
  .form-control {
    &:invalid,
    &:valid {
      background-image: none;
    }
  }
}

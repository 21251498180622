@mixin dropdown-caret() {
  content: "";
  border: solid;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}

.dropdown-menu {
  box-shadow: 0 0 50px rgba(82, 63, 105, 0.35);
  padding-bottom: 0;
  padding-top: 0;
  .dropdown-item:first-child {
    padding-top: 1rem;
  }
}

.dropdown-menu.fade {
  display: block;
  opacity: 0;
  pointer-events: none;
  height: 0;
}

.show > .dropdown-menu.fade {
  pointer-events: auto;
  opacity: 1;
  height: auto;
}

.dropdown-toggle::after {
  @include dropdown-caret;
  transform: rotate(45deg);
}

.dropup .dropdown-toggle::after {
  @include dropdown-caret;
  transform: rotate(-135deg);
}

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: $input-color !important;
}

.bootstrap-select .dropdown-menu {
  max-width: 100% !important;
}

@mixin util-by-breakpoint($propertyMap, $property) {
  @each $bp, $bpValue in $grid-breakpoints {
    //   @debug "in loop #{&}-#{$bp}-#{$size}";
    //   @debug $value $bpValue;
    @each $size, $value in $propertyMap {
      &-#{$bp}-#{$size} {
        @media screen and (min-width: $bpValue) {
          #{$property}: $value;
        }
      }
    }
  }
}
